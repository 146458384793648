<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/admin/menu">一级菜单列表</el-breadcrumb-item>
        <el-breadcrumb-item>二级菜单列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="Add()"
          size="medium"
          >新增二级菜单</el-button
        >
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="菜单名称"
              v-model="page.menu_name"
              clearable
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
       <el-form :inline="true"  :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="菜单名称" v-model="page.menu_name" clearable class="input-with-select" @clear="onSubmit">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="tl">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
       
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            prop="menu_sort"
            min-width="60"
            :show-overflow-tooltip="true"
            label="菜单排序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="menu_id"
            label="菜单ID"
            min-width="60"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="menu_parent_id"
            label="父菜单ID"
            min-width="60"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="menu_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="菜单名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="page_id"
            min-width="80"
            :show-overflow-tooltip="true"
            label="page_id"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="menu_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="菜单路径"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="menu_logo"
            min-width="80"
            :show-overflow-tooltip="true"
            label="菜单图标"
            align="center"
          ></el-table-column>
          <el-table-column label="是否审核后使用" min-width="80" align="center">
            <template slot-scope="scope">{{
              scope.row.audit_use_sate == 1 ? "否" : "是"
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="orange disib"
                size="mini"
                @click="goUpdate(scope.row.menu_parent_id, scope.row.menu_id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="red"
                size="medium"
                @click="godelete(scope.row.menu_id, scope.$index)"
                >删除</el-button
              >
              <el-button
                type="text"
                class="general_color disib"
                size="mini"
                @click="SetBtn(scope.row.menu_id)"
                >按钮管理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { mapState } from "vuex";
import { session } from "../../../util/util";
import { imageUrl } from "../../../config/index";
export default {
  data() {
    return {
      imageUrl: imageUrl,
      page: {
        pager_offset: "0",
        pager_openset: "10",
        menu_level: "2",
        menu_parent_id: "",
        menu_name: "",
      },
      currentPage: 1,
      total: 0,
      tableData: [],
    };
  },
  computed: {
    ...mapState({
      menuPage1: (state) => state.menu.menuPage1,
    }),
  },
  created() {
    this.page = Object.assign({}, this.menuPage1);
    this.page.menu_parent_id = session.get("params").id
      ? String(session.get("params").id)
      : "";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/menu/list", params).then((v) => {
        this.tableData = v.data.menu_list;
        this.total = v.data.pager_count;
      });
    },
    //编辑菜单
    goUpdate(index, index1) {
      session.set("params1", { id: index, id1: index1, name: "" });
      this.$router.push({
        name: "SetMenuTwoAdd",
      });
    },
    //菜单按钮管理
    SetBtn(index) {
      session.set("params1", { id: index, name: "" });
      this.$router.push({
        name: "BtnList",
      });
    },
    //新增二级菜单
    Add() {
      session.set("params1", { id: this.page.menu_parent_id, name: "" });
      this.$router.push({
        name: "SetMenuTwoAdd",
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("menu/setNewPage1", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("menu/setNewPage1", this.page);
      this.init(this.page);
    },
    godelete(val, index) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        axios.delete("/pc/menu/delete", { menu_id: String(val) }).then((v) => {
          this.tableData.splice(index, 1);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
